import { formatDate, getTime, convertSecondsToDays } from '@/helpers/format-date';

export function getDataField(data, field) {
  if (field === 'activePercent') return (data * 100).toFixed(2);
  if (
    field === 'activeTime'
    || field === 'idleTime'
    || field === 'productiveTime'
    || field === 'unproductiveTime'
    || field === 'neutralTime'
    || field === 'overwork'
    || field === 'plan'
    || field === 'workTime'
    || field === 'duration'
  ) {
    return convertSecondsToDays(data);
  }
  if (field === 'dayStart' || field === 'dayFinish') {
    return getTime(data);
  }
  if (field === 'isWorkingDay') {
    if (data === 1) return 'Рабочий';
    if (data === 0) return 'Выходной';
  }
  if (field === 'eventTime') return `${formatDate(data)} `;
  if (field === 'productivity') {
    switch (data) {
      case 1:
        return 'Продуктивно';
      case 0:
        return 'Непродуктивно';
      case 2:
        return 'Нейтрально';
      case 4:
        return 'Браузер';
      default:
        return '';
    }
  }
  return data;
}
